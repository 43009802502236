import React from "react";
import logo from "../images/logo-w.png";

const Footer = () => (
  <footer className="main-footer">
    <img src={logo} alt="logo SV Kamerové systémy" className="company-logo" />
    <nav>
      <ul className="d-flex">
        <li>
          <a href="#o-nas">O nás</a>
        </li>
        <li>
          <a href="#co-delame">Služby</a>
        </li>
        <li>
          <a href="#realizace">Realizace</a>
        </li>
        <li>
          <a href="#kontakty">Kontakty</a>
        </li>
      </ul>
    </nav>
    <p className="copy">
      &copy; 2021 by <a href="https://monium.cz">monium.cz</a>
    </p>
  </footer>
);

export default Footer;
