import React, { useEffect, useRef, useState } from "react";
import {
  BsPhone,
  FaRegEnvelope,
  FaRegUserCircle,
  FiMapPin,
} from "react-icons/all";
import ElementWithDropsquare from "./ElementWithDropsquare";
import mapboxgl from "mapbox-gl";
import { Waypoint } from "react-waypoint";
import { animateCSS } from "./animateCSS";

mapboxgl.accessToken =
  "pk.eyJ1IjoibWFydGluZm9sZHluYSIsImEiOiJja2tnbm9vN3oxNzByMm9xdXA2NWVtY3lmIn0.AEN3bo23yaZzhwT7Qol93g";

const Kontakty = ({ isMobile }) => {
  const [viewport] = useState({
    lng: 15.1858622,
    lat: 50.7335669,
    zoom: 15,
    scrollZoom: false,
  });

  let mapContainer = useRef();

  useEffect(() => {
    if (viewport) {
      const map = new mapboxgl.Map({
        container: mapContainer,
        style: "mapbox://styles/martinfoldyna/ckkgpfdrg0lc917ppax52jgjp",
        center: [viewport.lng, viewport.lat],
        zoom: viewport.zoom,
      });

      map.scrollZoom.disable();

      new mapboxgl.Marker({ color: "#C4C4C4", draggable: false })
        .setLngLat([15.18807284808, 50.7336178])
        .addTo(map);
    }
  }, [viewport.lat, viewport.lng, viewport.zoom, viewport]);

  return (
    <section className="section kontakty" id="kontakty">
      <div className="content ">
        <Waypoint
          onEnter={() => {
            animateCSS(".section.kontakty .fromLeft", "slideInLeft");
            animateCSS(".section.kontakty .fromRight", "slideInRight");
          }}
        >
          <div className="d-flex justify-content-between">
            <div className="toAnimate fromLeft">
              <h1>Kontakty</h1>
              <ul className="contacts">
                <li className="d-flex">
                  <FaRegUserCircle className="icon" />
                  <span>SV-System</span>
                </li>
                <li className="d-flex">
                  <FiMapPin className="icon" />
                  <span>
                    Podhorská 180/152
                    <br />
                    Jablonec nad Nisou
                    <br />
                    46602
                  </span>
                </li>
                <li className="d-flex">
                  <BsPhone className="icon" />
                  <span>
                    <a href="tel:+420770600116" className="tel">
                      +420 770 600 116
                    </a>
                    <a href="tel:+420608951708" className="tel">
                      +420 608 951 708
                    </a>
                  </span>
                </li>
                <li className="d-flex">
                  <FaRegEnvelope className="icon" />
                  <a href="mailto:info@sv-system.cz">info@sv-system.cz</a>
                </li>
              </ul>
              <div className="d-flex justify-content-end">
                <span className="info">Jsme plátci DPH.</span>
              </div>
            </div>
            <div className="mapbox-wrapper toAnimate fromRight">
              <ElementWithDropsquare>
                <div
                  className="mapbox-container"
                  ref={(el) => (mapContainer = el)}
                />
              </ElementWithDropsquare>
            </div>
          </div>
        </Waypoint>
      </div>
    </section>
  );
};

export default Kontakty;
