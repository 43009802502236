import React from "react";
import ImageSlider from "./ImageSlider";
import { Waypoint } from "react-waypoint";
import { animateCSS } from "./animateCSS";

const Realizace = () => (
  <section className="section realizace" id="realizace">
    <div className="content">
      <Waypoint
        onEnter={() => {
          animateCSS(".section.realizace .fromLeft", "slideInLeft");
          animateCSS(".section.realizace .fromRight", "slideInRight");
        }}
      >
        <div className="d-flex">
          <div className="flex-wrapper">
            <div className="d-flex align-items-center section-header toAnimate fromLeft">
              <h1 className="section-heading">Realizace</h1>
              <p className="section-description">
                Takto může vypadat Váš nový kamerový systém od naší společnosti.
              </p>
            </div>
            <div className="toAnimate fromRight">
              <div className="gallery">
                <ImageSlider />
              </div>
            </div>
          </div>
        </div>
      </Waypoint>
    </div>
  </section>
);

export default Realizace;
