import React from "react";
import { Waypoint } from "react-waypoint";
import { animateCSS } from "./animateCSS";

const AboutUs = () => (
  <section className="section o-nas" id="o-nas">
    <div className="content">
      <Waypoint
        onEnter={() => {
          animateCSS(".o-nas .fromLeft", "slideInLeft", "in");
          animateCSS(".o-nas .fromRight", "slideInRight", "in");
        }}
      >
        <div className="about-us d-flex">
          <h1 className="toAnimate fromLeft">Kdo jsme?</h1>
          <div className="toAnimate fromRight">
            <p className="text">
              Jsme profesionální firma sídlící v Jablonci nad Nisou, která
              domácnostem zajišťuje bezpečí na nejvyšší úrovni a společnostem
              poskytuje nejmodernější možnosti zabezpečení, ať už se týkají
              jednoduchých kamer nebo komplexních zabezpečovacích systémů.
              Zároveň zaručujeme profesionální přístup, kvalitní provedení
              montáží a poradenství.
            </p>
          </div>
        </div>
      </Waypoint>
    </div>
  </section>
);

export default AboutUs;
