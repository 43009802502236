import React, { Fragment, useState, useRef, useEffect } from "react";
import Input from "./Input";
import { BsArrowRight } from "react-icons/all";
import { animateCSS } from "./animateCSS";
import { Waypoint } from "react-waypoint";
import Recaptcha from "react-google-recaptcha";
import axios from "axios";

const KontaktForm = () => {
  const [name, setName] = useState("");
  const [suranme, setSurname] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [statusMessage, setStatusMessage] = useState({
    message: "",
    success: false,
  });

  const recaptchaRef = useRef();

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    const token = await recaptchaRef.current.executeAsync();

    if (token) {
      const template_params = {
        from_name: `${name} ${suranme}`,
        reply_to: email,
        to: "SV-System",
        subject: `Internetová poptávka ${new Date().toLocaleString("cs")}`,
        message,
      };
      const response = await axios.post(
        `https://api.emailjs.com/api/v1.0/email/send`,
        {
          service_id: process.env.REACT_APP_EMAILJS_SERVICE_ID,
          template_id: process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
          template_params,
          user_id: process.env.REACT_APP_EMAILJS_USER_ID,
        },
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      if (response.data.status === 200) {
        setStatusMessage({
          message: "Váš email byl úspěšně odeslán!",
          success: true,
        });

        setName("");
        setSurname("");
        setEmail("");
        setMessage("");
      } else {
        setStatusMessage({
          message: "Během odesílání se vyskytla chyba. Zkuste to prosím znovu.",
          success: false,
        });
      }
    }
  };

  useEffect(() => {
    if (statusMessage.message.length > 0) {
      setTimeout(() => {
        setStatusMessage({ message: "", success: false });
      }, 10000);
    }
  }, [statusMessage]);

  return (
    <Fragment>
      <section className="section kontakt-form" id="kontakt-form">
        <div className="content">
          <Waypoint
            onEnter={() => {
              animateCSS(".section.kontakt-form .fromLeft", "slideInLeft");
              animateCSS(".section.kontakt-form .fromRight", "slideInRight");
            }}
          >
            <div className="d-flex">
              <div className="section-header toAnimate fromLeft">
                <h1 className="section-heading">
                  Chcete využít našich služeb? Napište nám!
                </h1>
              </div>
              <div className="form-wrapper toAnimate fromRight">
                <form onSubmit={handleFormSubmit}>
                  <Recaptcha
                    sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA}
                    size="invisible"
                    ref={recaptchaRef}
                  />
                  <Input
                    type="text"
                    text="Jméno"
                    name="first-name"
                    className="m-0"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                  <Input
                    type="text"
                    text="Přijmení"
                    name="last-name"
                    className="m-0"
                    value={suranme}
                    onChange={(e) => setSurname(e.target.value)}
                  />
                  <Input
                    type="email"
                    text="Váš email"
                    name="email"
                    className={`m-0 ${email.length > 0 ? "forShow" : ""}`}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <div className="form-item textarea">
                    <textarea
                      className={`inputText`}
                      id="last_minute_popis"
                      name="last_minute_popis"
                      required
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                    />
                    <label
                      htmlFor="lastMinute_message"
                      className="floating-label"
                    >
                      Zde napište vaši poptávku.
                    </label>
                  </div>
                  <div className="d-flex justify-content-between">
                    <div className="form-messages">
                      {statusMessage && (
                        <span
                          className={`${
                            !statusMessage.success ? "text-danger" : ""
                          }`}
                        >
                          {statusMessage.message}
                        </span>
                      )}
                    </div>
                    <button className="btn d-flex align-items-center">
                      Odeslat poptávku <BsArrowRight className="btn-icon" />
                    </button>
                  </div>

                  <span className="recaptcha-legal">
                    Tento formulář je chráněn službou <strong>reCAPTCHA</strong>{" "}
                    a platí zde{" "}
                    <a
                      href="https://www.google.com/intl/cs/policies/privacy/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Ochrana soukromí
                    </a>
                    <span aria-hidden="true" role="presentation">
                      {" "}
                      a{" "}
                    </span>
                    <a
                      href="https://www.google.com/intl/cs/policies/terms/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Smluvní podmínky
                    </a>{" "}
                    společnosti Google
                  </span>
                </form>
              </div>
            </div>
          </Waypoint>
        </div>
      </section>
    </Fragment>
  );
};

export default KontaktForm;
