import React, { Fragment, useEffect } from "react";
import "./App.scss";
import "./MediaQueries.scss";
import Header from "./components/header";
import Hero from "./components/Hero";
import Work from "./components/Work";
import Realizace from "./components/Realizace";
import KontaktForm from "./components/KontaktForm.jsx";
import AboutUs from "./components/AboutUs";
import Footer from "./components/Footer";
import Kontakty from "./components/Kontakty";
import "animate.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function App() {
  const handleScroll = () => {
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;
    const height =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;
    const scrolled = (winScroll / height) * 100;
    document.getElementById("progressBar").style.height = scrolled + "%";
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
  }, []);

  return (
    <main className="App">
      <Header />
      <Fragment>
        <Fragment>
          <Hero />
          <AboutUs />
          <Work />
          <Realizace />
          <KontaktForm />
          <Kontakty isMobile={false} />
          <Footer />
        </Fragment>
        <div id="progressBarWrapper">
          <div id="progressBar" />
        </div>
      </Fragment>
    </main>
  );
}

export default App;
