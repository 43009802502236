export const animateCSS = (
  element,
  animation,
  direction = "in",
  prefix = "animate__"
) =>
  // We create a Promise and return it
  new Promise((resolve, reject) => {
    const animationName = `${prefix}${animation}`;
    const node = document.querySelector(element);
    if (!node.classList.contains("animate__animated")) {
      node.classList.add(`${prefix}animated`, animationName);

      if (direction === "in") {
        node.style.opacity = 1;
      }

      // When the animation ends, we clean the classes and resolve the Promise
      function handleAnimationEnd(event) {
        event.stopPropagation();
        if (direction === "out") {
          node.style.opacity = 0;
        }
        // node.classList.remove(`${prefix}animated`, animationName);
        // node.classList.remove("animationFinished");
        resolve("Animation ended");
      }

      node.addEventListener("animationend", handleAnimationEnd, { once: true });
    }
  });
