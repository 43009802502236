import React, { useState } from "react";
import { IoMdClose, RiMenu3Fill } from "react-icons/all";
import logo from "../images/logo-w.png";

const Header = () => {
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  return (
    <header className="main-header d-flex justify-content-between align-items-center p-1">
      <div className="d-flex justify-content-between">
        <img
          src={logo}
          alt="logo SV Kamerové systémy"
          className="company-logo"
        />
        {/*<SvgWLogo style={{ fontSize: "2rem" }} />*/}
        <logo />
        {showMobileMenu ? (
          <IoMdClose
            className="menu-icon text-white"
            onClick={() => setShowMobileMenu((prevState) => !prevState)}
          />
        ) : (
          <RiMenu3Fill
            className="menu-icon"
            onClick={() => setShowMobileMenu((prevState) => !prevState)}
          />
        )}
      </div>
      <nav className={`main-nav ${showMobileMenu ? "shown" : ""}`}>
        <ul className="main-nav-list">
          <li>
            <a
              href="#o-nas"
              onClick={() => setShowMobileMenu((prevState) => !prevState)}
            >
              O nás
            </a>
          </li>
          <li>
            <a
              href="#co-delame"
              onClick={() => setShowMobileMenu((prevState) => !prevState)}
            >
              Služby
            </a>
          </li>
          <li>
            <a
              href="#realizace"
              onClick={() => setShowMobileMenu((prevState) => !prevState)}
            >
              Realizace
            </a>
          </li>
          <li>
            <a
              href="#kontakty"
              onClick={() => setShowMobileMenu((prevState) => !prevState)}
            >
              Kontakty
            </a>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
