import React from "react";
import realizace01 from "../images/realizace01.jpg";
import realizace02 from "../images/realizace02.jpg";
import realizace03 from "../images/realizace03.jpg";
import realizace04 from "../images/realizace04.jpg";
import realizace05 from "../images/realizace05.jpg";
import realizace06 from "../images/realizace06.jpg";
import Slider from "react-slick";
import { AiFillCaretLeft, AiFillCaretRight } from "react-icons/ai";

const NextArrow = ({ onClick }) => (
  <div className="arrow arrow-left" onClick={onClick}>
    <AiFillCaretLeft />
  </div>
);

const PrevArrow = ({ onClick }) => (
  <div className="arrow arrow-right" onClick={onClick}>
    <AiFillCaretRight />
  </div>
);

const ImageSlider = () => {
  const settings = {
    dots: false,
    infinite: true,
    lazyLoad: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <PrevArrow />,
    prevArrow: <NextArrow />,
    responsive: [
      {
        breakpoint: 480,
        settings: { slidesToShow: 1 },
      },
    ],
  };

  return (
    <div>
      <Slider {...settings}>
        <div className="slick-item">
          <img src={realizace04} alt="první realizace" />
        </div>
        <div className="slick-item">
          <img src={realizace01} alt="druhá realizace" />
        </div>
        <div className="slick-item">
          <img src={realizace06} alt="třetí realizace" />
        </div>
        <div className="slick-item">
          <img src={realizace03} alt="čtvrtá realizace" />
        </div>
        <div className="slick-item">
          <img src={realizace05} alt="pátá realizace" />
        </div>
        <div className="slick-item">
          <img src={realizace02} alt="šestá realizace" />
        </div>
      </Slider>
    </div>
  );
};

export default ImageSlider;
