import React from "react";
import ElementWithDropsquare from "./ElementWithDropsquare";
import kamera from "../images/kamera.jpg";

const Hero = () => (
  <section className="section hero">
    <div className="content">
      <div className="d-flex justify-content-between align-items-center main-heading">
        <div className="text animate__animated animate__slideInLeft">
          <h1>
            Zajistíme Vám <br /> větší bezpečnost <br /> domu, majetku i firmy
          </h1>
        </div>
        {/*<div className="image-dropsquare-wrapper">*/}
        {/*  <img src={kamera} alt="Kamera" className="with-dropsquare" />*/}
        {/*</div>*/}
        <ElementWithDropsquare className="animate__animated animate__slideInRight">
          <img src={kamera} alt="Kamera" className="with-dropsquare" />
        </ElementWithDropsquare>
      </div>
    </div>
  </section>
);

export default Hero;
