import React from "react";
import { Waypoint } from "react-waypoint";
import { animateCSS } from "./animateCSS";

const Work = () => {
  return (
    <section className="section our-work" id="co-delame">
      <div className="content">
        <Waypoint
          onEnter={() => {
            animateCSS(".our-work .fromLeft", "slideInLeft", "in");
            animateCSS(".our-work .fromRight", "slideInRight", "in");
          }}
        >
          <div>
            <h1 className="section-heading toAnimate fromLeft">
              Čím se zabýváme?
            </h1>
            <div className="d-flex cards-wrapper toAnimate fromRight">
              <div className="card">
                <h2 className="card-header">Kamery</h2>
                <p>Stavíme kamerové systémy na míru.</p>
              </div>
              <div className="card">
                <h2 className="card-header">Zabezpečovací systémy</h2>
                <p>
                  Návrh, montáž a servis provádíme na základě certifikovaného
                  školení přímo od českého výrobce Jablotron.
                </p>
              </div>
              <div className="card">
                <h2 className="card-header">
                  Prodej a servis výpočetní techniky
                </h2>
                <p>Profesionální servis osobních počítačů a notebooků.</p>
              </div>
            </div>
          </div>
        </Waypoint>
      </div>
    </section>
  );
};

export default Work;
