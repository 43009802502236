import React from "react";

const ElementWithDropsquare = ({ children, className, ...rest }) => (
  <div
    className={`element-dropsquare-wrapper ${className ? className : ""}`}
    {...rest}
  >
    <div>
      <div className="dropsquare" />
      {children}
    </div>
  </div>
);

export default ElementWithDropsquare;
